import { AfterViewInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { AiHulpModalComponent } from 'src/app/modules/general/modals/ai-hulp/ai-hulp.modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) { }

  public lookupText: any;

  public onKey(key: any) {
    let route: string;

    // Check if input is a valid number
    if (!isNaN(Number(this.lookupText))) {
      route = '/taxatie/detail/' + this.lookupText.replace(/[^\d.-]/g, '');
    }
    // Check if input is alphabetic characters only
    else if (/^[a-zA-Z ]+$/.test(this.lookupText)) {
      route = '/crm/relaties/' + this.lookupText;
    }
    // Check if input starts with 'T' or 't' followed by a number
    else if ((this.lookupText.match(/^t\d/i) !== null && this.lookupText.length != 6)) {
      route = '/taxatie/detail/' + this.lookupText.replace(/[^\d.-]/g, '');
    }
    // Fallback case
    else {
      route = '/taxatie/zoeken/' + this.lookupText;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    this.router.navigateByUrl(route)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });

    this.lookupText = '';
  }

  public openAiModal() {
    // Get the full URL from the Router
    const currentUrl = this.router.url;
  
    let valuationId: number | null = null;
  
    // Check if the URL contains /taxatie/detail/ and extract the ID if it exists
    if (currentUrl.includes('/taxatie/detail/')) {
      const segments = currentUrl.split('/');
      valuationId = Number(segments[segments.length - 1]) || null;
    }
  
    // Open the AI modal, passing the valuationId (can be null)
    this.dialog.open(AiHulpModalComponent, {
      width: '800px',
      data: { valuationId: valuationId }
    });
  }
  
  public logout() {
    this.authService.logout();
  }
}
