import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { saveState } from '../../../models/persistence.model';

@Injectable()
export class PersistenceService {
    // Array of objects to exclude parameters from
    private excludedUrls: { url: string, stripParams: boolean }[] = [
        { url: '/taxatie/zoeken', stripParams: true },
        // Add other URLs to exclude here if needed
        { url: '/crm/relatie-detail', stripParams: true }
    ];
    
    constructor(private router: Router) { }

    public saveState(data: {}) {
        const url = this.router.url;

        // Check if the current URL is in the excluded URLs list
        const excludedUrl = this.getExcludedUrl(url);
        if (excludedUrl) {
            // Use the URL as specified in the excludedUrls list
            this.updateState(data, excludedUrl.url);
            return;
        }

        const saveState: saveState = {
            created: new Date(),
            pageUrl: url,
            storageData: data
        }

        const storageData = [];
        const savedStates = localStorage.getItem('PersistenceMemory');
        if (savedStates != null) {
            let updated = false; // Flag to track if the state was updated

            for (const item of JSON.parse(savedStates)) {
                // Check if the URL has the same identifier before it
                if (url === item.pageUrl) {
                    // If a state with the same URL exists, update it
                    storageData.push(saveState);
                    updated = true;
                } else {
                    storageData.push(item);
                }
            }

            if (!updated) {
                // If no existing state was updated, add the new state
                storageData.push(saveState);
            }
        } else {
            // If there are no saved states, add the new state
            storageData.push(saveState);
        }

        localStorage.setItem('PersistenceMemory', JSON.stringify(storageData));
    }

    private getExcludedUrl(url: string) {
        // Return the object from excludedUrls if the URL is in the excluded URLs list
        return this.excludedUrls.find(excludedUrl => url.startsWith(excludedUrl.url));
    }

    public updateState(data: any, page: string) {
        const saveState: saveState = {
            created: new Date(),
            pageUrl: page,
            storageData: data
        }

        const storageData = [];
        const savedStates = localStorage.getItem('PersistenceMemory');
        if (savedStates != null) {
            for (const item of JSON.parse(savedStates)) {
                // Check if the URL is not in the excluded URLs list
                if (!this.isExcludedUrl(item.pageUrl)) {
                    storageData.push(item);
                }
            }
        }

        storageData.push(saveState);
        localStorage.setItem('PersistenceMemory', JSON.stringify(storageData));
    }

    private isExcludedUrl(url: string): boolean {
        // Check if the URL is in the excluded URLs list
        return this.excludedUrls.some(excludedUrl => url.startsWith(excludedUrl.url));
    }

    public retrieveState(itemToRetrieve?: string): saveState | undefined {
        const savedStates = JSON.parse(localStorage.getItem('PersistenceMemory') || '[]');
        if (savedStates != null) {
            if (itemToRetrieve == null) {
                itemToRetrieve = this.router.url.split('/')[1];
            }
            const filter = savedStates.filter((x: saveState) => x.pageUrl.startsWith(itemToRetrieve ?? ''));
            if (filter.length != 0) {
                return (filter[0]);
            }
        }
    }

    public removeState(itemToRemove?: string) {
        const storageData = [];
        const savedStates = localStorage.getItem('PersistenceMemory');
        if (itemToRemove == null) {
            itemToRemove = this.router.url.split('/')[1];
        }

        if (savedStates != null) {
            for (const item of JSON.parse(savedStates)) {
                // Check if stored URL starts with itemToRemove
                if (!item.pageUrl.startsWith(itemToRemove)) {
                    storageData.push(item);
                }
            }
            localStorage.setItem('PersistenceMemory', JSON.stringify(storageData));
        }
    }

    public removeAllStates() {
        localStorage.removeItem('PersistenceMemory');
    }

    public getCurrentSize() {
        if (localStorage.getItem('PersistenceMemory') != null) {
            return JSON.parse(localStorage.getItem('PersistenceMemory') || '[]').length;
        } else {
            return 0;
        }
    }
}
