import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { PersistenceService } from 'src/app/modules/general/services/persistence.service';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  amount?: number;  // amount is either a number or undefined
  showAmount?: boolean;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  showAmount?: boolean;
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

@Injectable()
export class MenuItems {
  MENUITEMS: Menu[] = [
    {
      state: '/general/dashboard',
      name: 'Dashboard',
      type: 'link',
      showAmount: false,
      icon: 'dashboard'
    },
    {
      state: 'taxatie/taxaties',
      name: 'Taxaties',
      type: 'sub',
      showAmount: true,
      icon: 'directions_car',
      children: [
        { state: 'spoed', name: 'Spoed', amount: undefined, showAmount: true },
        { state: '24uur', name: '24 uur', amount: undefined, showAmount: true },
        { state: 'getaxeerd', name: 'Getaxeerd', amount: undefined, showAmount: true },
        { state: 'kopen', name: 'Kopen', amount: undefined, showAmount: true },
        { state: 'gekocht', name: 'Gekocht', amount: undefined, showAmount: true },
        { state: 'verkocht', name: 'Verkocht', amount: undefined, showAmount: true },
        { state: 'leverbaar', name: 'Leverbaar', amount: undefined, showAmount: true },
        { state: 'inspectie', name: 'Inspectie', amount: undefined, showAmount: true },
        { state: 'onderweg', name: 'Onderweg', amount: undefined, showAmount: true },
        { state: 'afgehandeld', name: 'Afgehandeld', amount: undefined, showAmount: false },
      ]
    },
    {
      state: '/taxatie/overzichten',
      name: 'Overzichten',
      type: 'sub',
      icon: 'list',
      showAmount: false,
      children: [
        { state: 'taken', name: 'Open taken' },
        { state: 'verhogingen', name: 'Verhogingen' },
        { state: 'taxaties-controleren', name: 'Taxaties controleren' },
        { state: 'dubbel-check', name: 'Dubbel check' },
        { state: 'nabellen', name: 'Nabellen' },
        { state: 'factuur-niet-binnen', name: 'Geen factuur' },
        { state: 'te-inspecteren', name: 'Te inspecteren' },
        { state: 'te-betalen', name: 'Te betalen' },
        { state: 'bedrijfsvoorraad', name: 'Taxaties in BV' },
        { state: 'verschillen-voortaxaties', name: 'Verschillen voortaxaties' },
        { state: 'stand-up', name: 'Stand-up' },
        { state: 'recent-verkocht', name: 'Recent verkocht' },
        { state: 'hogere-biedingen', name: 'Hogere biedingen' },
        { state: 'biedtermijn-resultaat', name: 'Biedtermijn resultaat' },
        { state: 'inspecties-beoordelen', name: 'Inspecties beoordelen' },
      ]
    },
    {
      state: 'claim',
      name: 'Claims',
      type: 'sub',
      showAmount: false,
      icon: 'car_crash',
      children: [
        { state: 'nieuw', name: 'Nieuw' },
        { state: 'in-behandeling', name: 'In behandeling' },
        { state: 'verwerkt', name: 'Verwerkt' },
        { state: 'afgehandeld', name: 'Afgehandeld' },
      ]
    },
    {
      state: '/crm/relaties',
      name: 'Relaties',
      type: 'link',
      showAmount: false,
      icon: 'supervisor_account'
    },
    {
      state: '/taxatie/zoeken',
      name: 'Taxaties zoeken',
      type: 'link',
      icon: 'search'
    },
    {
      state: '/voertuig-lijst/overzicht',
      name: 'Voertuiglijst',
      type: 'link',
      icon: 'checklist',
    },
    {
      state: '/general/technisch',
      name: 'Technisch',
      type: 'sub',
      showAmount: false,
      icon: 'layers',
      children: [
        { state: 'mappings', name: 'Mappings' },
        { state: 'biedtermijnen', name: 'Biedtermijnen' },
        { state: 'nieuwsberichten', name: 'Nieuwsberichten' },
        { state: 'import-queue', name: 'Aanvragen in wachtrij' },
        { state: 'queue', name: 'Queue' },
        { state: 'gaspedaal', name: 'Gaspedaal' },
        { state: 'autotrack', name: 'Autotrack' },
      ]
    },
    {
      state: '/statistieken',
      name: 'Statistieken',
      type: 'sub',
      showAmount: false,
      icon: 'show_chart',
      children: [
        { state: 'autoverkoop-partner', name: 'Autverkoop Partner' },
        { state: 'statistieken', name: 'Statistieken' },
      ]
    },
  ];

  constructor(
    private persistence: PersistenceService,
    private authService: AuthenticationService
  ) {
    // Get authenticated user information
    const data = this.authService.getAuth();

    if(data) {
      if(data.username === 'wouterdoornbos' || data.username === 'nvdveen' || data.username === 'berrybuitenhuis' || data.username === 'maartenmarissink') {
        // If the user matches add twinfield to children of Technisch
        this.MENUITEMS[7].children?.push({ state: 'twinfield', name: 'Twinfield' });
      }
    }
  }

  getMenuitem(): Menu[] {
    const dashboardState: { storageData: { counterData?: any } } | undefined = this.persistence.retrieveState('/general/dashboard');
    const amountData = dashboardState ? dashboardState.storageData['counterData'] : null;

    if (amountData) {
      const children = this.MENUITEMS[1]?.children;

      if (children) {
        children[0].amount = amountData.Spoed?.paginator.records;
        children[1].amount = amountData.Tax24uur?.paginator.records;
        children[2].amount = amountData.Getaxeerd?.paginator.records;
        children[3].amount = amountData.Kopen?.paginator.records;
        children[4].amount = amountData.Gekocht?.paginator.records;
        children[5].amount = amountData.Verkocht?.paginator.records;
        children[6].amount = amountData.Leverbaar?.paginator.records;
        children[7].amount = amountData.Inspectie?.paginator.records;
        children[8].amount = amountData.Onderweg?.paginator.records;
        children[9].amount = amountData.Afgehandeld?.paginator.records;
      }
    }

    return this.MENUITEMS;
  }
}
