import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/modules/api.service';
import { PersistenceService } from 'src/app/modules/general/services/persistence.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class AppSidebarComponent implements AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  mobileQuery!: MediaQueryList;
  public UserData: any;
  urgentValuations = false;
  private _mobileQueryListener!: () => void;
  private urgentValuationsInterval: any;


  constructor(
    private router: Router,
    private apiService: ApiService,
    private persistence: PersistenceService,
    public menuItems: MenuItems,
    private authService: AuthenticationService
  ) {
    this.UserData = JSON.parse(localStorage.getItem('authUserInformation') || '{}');
    // Enable the interval to check for urgent valuations
    // this.startUrgentValuationsInterval();
  }

  private startUrgentValuationsInterval() {
    // Ensure the previous interval is cleared before starting a new one
    if (this.urgentValuationsInterval) {
      clearInterval(this.urgentValuationsInterval);
    }

    // Start the new interval
    this.urgentValuationsInterval = setInterval(() => {
      this.checkForUrgentValuations();
    }, 12 * 10000);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    // Always clear the interval when the component is destroyed to prevent memory leaks
    if (this.urgentValuationsInterval) {
      clearInterval(this.urgentValuationsInterval);
    }
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        this.markActiveMenuItems((event as NavigationEnd).url);
      });

    // Mark active menu items on component initialization
    this.markActiveMenuItems(window.location.pathname);
  }

  markActiveMenuItems(currentRoute: any) {
    const currentPath = new URL(currentRoute, window.location.origin).pathname;
    // loop through all menu items and mark the active one
    const menuItems = document.querySelectorAll('.sidebar-menu-item');
    menuItems.forEach(menuItem => {
      const aTag = menuItem.querySelector('a');
      if (aTag) {
        const href = aTag.getAttribute('href');
        if (href) {
          const menuItemPath = new URL(href, window.location.origin).pathname;
          if (currentPath === menuItemPath) {
            menuItem.classList.add('active');
          } else {
            menuItem.classList.remove('active');
          }
        }
      }
    });

    // loop through all sub-menu items and mark the active ones
    const subMenuItems = document.querySelectorAll('.sub-item-a');
    subMenuItems.forEach(subMenuItem => {
      const aTag = subMenuItem.querySelector('a');
      if (aTag) {
        const href = aTag.getAttribute('href');
        const menuItemPath = href ? new URL(href, window.location.origin).pathname : '';
        if (currentPath === menuItemPath) {
          subMenuItem.classList.add('active');
        } else {
          subMenuItem.classList.remove('active');
        }
      }
    });
  }

  checkForUrgentValuations() {
    this.apiService.getValuations(1, 1, null, JSON.stringify({ AND: [["isTreated", "eq", "false"], ["ValuationType.name", "eq", "urgent"], ["StatusType.name", "eq", "new"]] }), JSON.stringify(["id"]), '').subscribe(res => {
      if (res.paginator.records > 0) {
        this.setUrgentCounter(res);
        this.urgentValuations = true;
      } else {
        this.setUrgentCounter(res);
        this.urgentValuations = false;
      }
    });

    this.apiService.getValuations(1, 1, null, JSON.stringify({ AND: [["ValuationType.name", "eq", "urgent"], ["StatusType.name", "eq", "new"]] }), JSON.stringify(["id"]), '').subscribe(res => {
      if (res.paginator.records > 0) {
        this.setUrgentTotalCounter(res);
        this.urgentValuations = true;
      } else {
        this.setUrgentTotalCounter(res);
        this.urgentValuations = false;
      }
    });
  }

  setUrgentCounter(res: any) {
    const dashboardState = this.persistence.retrieveState('/general/dashboard');
    if (dashboardState) {
      let amountData: any = dashboardState.storageData || {};
      amountData.counterData = amountData.counterData || {};
      amountData.counterData['Spoed'] = res;
      this.persistence.updateState(amountData, '/general/dashboard');
    }
  }

  setUrgentTotalCounter(res: any) {
    const dashboardState = this.persistence.retrieveState('/general/dashboard');
    if (dashboardState) {
      let amountData: any = dashboardState.storageData || {};
      amountData.counterData = amountData.counterData || {};
      amountData.counterData['SpoedTotaal'] = res;
      this.persistence.updateState(amountData, '/general/dashboard');
    }
  }

  logout() {
    this.authService.logout();
  }
}

