<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="user-profile">
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="profile-text">
        <a [matMenuTriggerFor]="sdprofile" class="">
            <span *ngIf="UserData && UserData.Person">{{UserData.Person.firstName}} {{UserData.Person.infix}} {{UserData.Person.lastName}}</span> 
            <span *ngIf="!UserData">Onbekend</span> 
            <i class="ti-angle-down font-12 m-l-5"></i>
        </a>
    </div>
    <mat-menu #sdprofile="matMenu" class="mymegamenu">
        <button [routerLink]="['general/instellingen']" mat-menu-item>
            <mat-icon>settings</mat-icon> Instellingen
        </button>
        <button (click)="logout()" [routerLink]="['/', 'authentication/logout']" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon> Uitloggen
        </button>
    </mat-menu>
</div>
<mat-nav-list appAccordion>
    <mat-list-item class="sidebar-menu-item" appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" group="{{menuitem.state}}">
        <a appAccordionToggle [routerLink]="[menuitem.state]" routerLinkActive="selected" *ngIf="menuitem.type === 'link'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a appAccordionToggle routerLinkActive="selected" *ngIf="menuitem.type === 'sub'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}aa</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
            <mat-list-item class="sub-item-a" *ngFor="let childitem of menuitem.children" routerLinkActive="selected">
                <a class="relative" [routerLink]="[menuitem.state, childitem.state ]">
                    <mat-chip-listbox *ngIf="menuitem.showAmount == true">{{childitem.name}}&nbsp;
                        <mat-chip-option *ngIf="childitem.name == 'Spoed'  && childitem.showAmount == true"
                            [class.isUrgent]="urgentValuations == true" disabled="true">
                            <span *ngIf="childitem.amount != null">{{childitem.amount | number:'0.0-0'}} /
                                {{childitem.amountTotal | number:'0.0-0'}}</span>
                            <span *ngIf="childitem.amount == null">-</span>
                        </mat-chip-option>

                        <mat-chip-option *ngIf="childitem.name != 'Spoed'  && childitem.showAmount == true" disabled="true">
                            <span *ngIf="childitem.amount != null">{{childitem.amount | number:'0.0-0'}}</span>
                            <span *ngIf="childitem.amount == null">-</span>
                        </mat-chip-option>
                    </mat-chip-listbox>
                    <span *ngIf="menuitem.showAmount == false">{{childitem.name}}</span>
                </a>
            </mat-list-item>
        </mat-nav-list>
    </mat-list-item>
</mat-nav-list>
