export const environment = {
  production: false,
  apiUrl: 'https://api-new-acc.autotaxatiepartners.nl',
  application: 'atp-admin',
  password: 'FDWAx;n|nBT5^fwxjY^C',
  // First item will be the default, one entry will hide the field.
  level: ['person'],
  websocketURL: 'wss://m27ul7t5cb.execute-api.eu-west-1.amazonaws.com/acc/',

  openAiSecretKey: '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',
  openAiOrganizationId: 'org-d5mWXCTUaDOhuxNHVxRdmBwl'
};