<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<mat-form-field matTooltip="Zoeken op Taxatienummer, Kenteken of Bedrijfsnaam."
    style="width: 150px !important; color:white; font-size: 24px;">
    <input autocomplete="off" style="color:white;" [(ngModel)]="lookupText" (keyup.enter)="onKey($event)" matInput>
</mat-form-field>

<button mat-button id="ai-button" style="color: white;" class="m-t-15" (click)="openAiModal()">
    <mat-icon>lightbulb</mat-icon>
</button>

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button mat-button style="color:white;" [matMenuTriggerFor]="profile" class="m-r-5 m-t-20">
    <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> </button>
<mat-menu id="topmenu" style="color:white;" #profile="matMenu" class="mymegamenu">
    <button [routerLink]="['general/instellingen']" mat-menu-item>
        <mat-icon>settings</mat-icon> Instellingen
    </button>
    <button (click)="logout()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon> Uitloggen
    </button>
</mat-menu>