import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  OnInit
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { MatSidenav } from '@angular/material/sidenav';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  minisidebar!: boolean;
  sidebarOpened!: boolean;
  private _mobileQueryListener: () => void;

  @ViewChild('snav') sidenav!: MatSidenav;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  toggleSidenav() {
    if (this.sidenav.opened == false) {
      localStorage.setItem('sidemenuOpen', 'true');
    } else {
      localStorage.setItem('sidemenuOpen', 'false');
    }
    this.sidenav.toggle();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (localStorage.getItem('sidemenuOpen') == 'false') {
        this.sidenav.close();
      }
    }, 0)
  }
}
