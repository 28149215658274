import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {
    constructor(private snackBar: MatSnackBar) {
    }

    public openSnackBar(title: string, button: string) {
        this.snackBar.open(title, button, {
            verticalPosition: 'top',
            duration: 5000,
        });
    }

    public dismiss() {
        this.snackBar.dismiss();
    }
}