import { Injectable } from '@angular/core';
import OpenAI from 'openai';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpenAIService {
  private client: OpenAI;

  constructor() {
    // Initialize the OpenAI client
    this.client = new OpenAI({
      apiKey: environment.openAiSecretKey,
      organization: environment.openAiOrganizationId,
      dangerouslyAllowBrowser: true,
    });
  }

  /**
   * Create a regular chat completion (non-streaming).
   * @param messages Array of chat messages for the conversation.
   * @param model The model to use (e.g., 'gpt-4').
   * @returns Promise with the full OpenAI chat completion response.
   */
  async createChatCompletion(
    messages: OpenAI.Chat.ChatCompletionCreateParams['messages'],
    model: string = 'gpt-4o'
  ): Promise<string> {
    try {
      const params: OpenAI.Chat.ChatCompletionCreateParams = {
        messages,
        model,
      };

      const response = await this.client.chat.completions.create(params);

      // Extract the assistant's reply
      const content = response.choices[0].message.content;
      if (content === null) {
        throw new Error('Received null content from OpenAI');
      }
      return content;
    } catch (error) {
      console.error('Error creating chat completion:', error);
      throw error;
    }
  }

  /**
   * Create a streaming chat completion.
   * @param messages Array of chat messages for the conversation.
   * @param model The model to use (e.g., 'gpt-4').
   * @returns Async generator that yields individual chunks of the streamed response.
   */
  async *streamChatCompletion(
    messages: OpenAI.Chat.ChatCompletionCreateParams['messages'],
    model: string = 'gpt-4o'
  ): AsyncGenerator<string, void, undefined> {
    try {
      const params: OpenAI.Chat.ChatCompletionCreateParamsStreaming = {
        messages,
        model,
        stream: true, // Enable streaming
      };

      const stream = await this.client.chat.completions.create(params);

      // Yield chunks as they arrive
      for await (const chunk of stream) {
        const deltaContent = chunk.choices[0]?.delta?.content || '';
        if (deltaContent) {
          yield deltaContent;
        }
      }
    } catch (error) {
      console.error('Error with streaming chat completion:', error);
      throw error;
    }
  }

  /**
   * Append a user or assistant message to the chat history.
   * This is a helper method to maintain the chat context.
   * @param history The chat message history array.
   * @param role The role of the new message ('user' or 'assistant').
   * @param content The content of the new message.
   */
  addMessage(
    history: OpenAI.Chat.ChatCompletionCreateParams['messages'],
    role: 'user' | 'assistant' | 'system',
    content: string
  ): void {
    history.push({ role, content });
  }

  /**
   * Clear the chat history.
   * @returns An empty message history array.
   */
  clearMessages(): OpenAI.Chat.ChatCompletionCreateParams['messages'] {
    return [];
  }

  /**
   * Analyze an image with a given prompt, integrating with existing messages.
   * @param prompt The prompt or description for the analysis.
   * @param imageFile The image file to analyze (optional).
   * @param imageUrl The URL of the image for analysis (optional).
   * @param messages Existing message history to extend (optional).
   * @returns Promise with the analysis result.
   * @throws Error if neither imageFile nor imageUrl is provided, or if both are provided.
   */
  async analyzeImage(
    prompt: string,
    imageFile?: File,
    imageUrl?: string,
    messages: OpenAI.Chat.ChatCompletionCreateParams['messages'] = []
  ): Promise<string> {
    try {
      // Validate input: Only one of imageFile or imageUrl must be provided
      if (!imageFile && !imageUrl) {
        throw new Error('You must provide either an image file or an image URL.');
      }
      if (imageFile && imageUrl) {
        throw new Error('You cannot provide both an image file and an image URL. Choose one.');
      }

      // Prepare the image content for image URL or Base64
      const imageContent: any = imageUrl
        ? {
          type: 'image_url',
          image_url: { url: imageUrl },
        }
        : {
          type: 'image_base64',
          base64: `data:image/jpeg;base64,${await this.convertFileToBase64(imageFile!)}`,
        };

      // Add the prompt and image content to the messages
      messages.push({
        role: 'user',
        content: [
          { type: 'text', text: prompt },
          imageContent,
        ],
      });

      // Send the chat completion request
      const response = await this.client.chat.completions.create({
        model: 'gpt-4o',
        messages,
      });

      return response.choices[0]?.message?.content || 'No analysis result returned.';
    } catch (error) {
      console.error('Error analyzing image:', error);
      throw error;
    }
  }

  /**
   * Helper method to convert a file to a Base64 string.
   * @param file The file to convert.
   * @returns Promise with the Base64 string.
   */
  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('Failed to convert file to Base64.'));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }
}
