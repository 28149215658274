import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate() {
        if (this.authenticationService.checkLoginState() == true) {
            return true;
        } else {
            // We have to use JS window, the router for the component did not load yet.
            const pathname = window.location.pathname;
            if (pathname != '/authentication/login') {
                if (!pathname.includes('/authentication/login/')) {
                    // Save base64 url to current url.
                    this.router.navigate(['authentication/login/' + btoa(pathname)]);
                }
            } else {
                this.router.navigate(['authentication/login']);
            }
            return false;
        }
    }
}
