import { Directive, Renderer2, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appDisableAutocomplete]'
})
export class DisableAutocompleteDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    const inputElements = this.el.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      this.renderer.setAttribute(input, 'autocomplete', 'off');
    });
  }

}
