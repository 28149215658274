import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MaintenanceService } from './authentication/helpers/maintenance.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private maintenanceService: MaintenanceService,
    private router: Router,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
  ) { }

  ngOnInit() {
    // Subscribe to maintenance status updates
    this.subscribeToMaintenance();
    // Check for service worker updates
    this.checkForServiceWorkerUpdate();
  }

  private subscribeToMaintenance() {
    this.maintenanceService.isMaintenance$.subscribe((isMaintenance) => {
      // If maintenance mode is enabled and the current route is not '/maintenance', navigate to '/maintenance'
      if (isMaintenance && this.router.url !== '/maintenance') {
        this.router.navigate(['/maintenance']);
      }
    });
  }

  private checkForServiceWorkerUpdate() {
    if (this.swUpdate.isEnabled) {
      console.log('Service Worker is enabled');
      this.swUpdate.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY') {
          // Show update notification to the user
          this.showUpdateNotification();
          console.log('New version available');
        }
      });
      console.log('Checking for updates');
    }
  }  

  private showUpdateNotification() {
    const snackBarRef = this.snackBar.open('Er is een nieuwe frontend versie beschikbaar', 'Nu bijwerken', {
      duration: 0, // Make the snackbar persistent until manually dismissed
    });
    snackBarRef.onAction().subscribe(() => {
      this.swUpdate.activateUpdate().then(() => {
        console.log('Activating new version');
        // Reload the page to activate the new version
        window.location.reload();
      });
    });
  }
}
