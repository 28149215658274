import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, LOCALE_ID, NgModule, APP_INITIALIZER, ErrorHandler, isDevMode } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MomentModule } from 'ngx-moment';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthGuard } from './authentication/helpers/auth.guard';
import { JwtInterceptor } from './authentication/helpers/jwt.interceptor';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { MaterialModule } from './material-module';
import { ApiService } from './modules/api.service';
import { PersistenceService } from './modules/general/services/persistence.service';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SnackBarService } from './modules/general/services/snackbar.service';
import { MatInputModule } from '@angular/material/input';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import moment from 'moment';
import * as Sentry from "@sentry/angular-ivy";
import 'moment-timezone';
import { DisableAutocompleteDirective } from './directives/disable-autocomplete.directive';
import { MaintenanceComponent } from './shared/maintenance/maintenance.component';
import { MaintenanceService } from './authentication/helpers/maintenance.service';
import { MaintenanceInterceptor } from './authentication/helpers/maintenance.interceptor';
import { GlobalErrorHandler } from './modules/global-error-handler';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { OpenAIService } from './modules/general/services/openai.service';
registerLocaleData(localeNl);

export function configureMomentTimezone() {
  return () => {
    moment.tz.setDefault('Europe/Amsterdam');
  };
}

@Injectable()

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    AppBlankComponent,
    DisableAutocompleteDirective,
    MaintenanceComponent
  ],
  imports: [
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    MatAutocompleteModule,
    RouterModule.forRoot(AppRoutes),
    NgxGalleryModule,
    AutocompleteLibModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    ApiService,
    MaintenanceService,
    SnackBarService,
    PersistenceService,
    OpenAIService,
    GlobalErrorHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    {
      provide: APP_INITIALIZER,
      useFactory: configureMomentTimezone,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
