<h2 mat-dialog-title>AI Assistent</h2>
<div mat-dialog-content>
  <div *ngFor="let message of filteredMessages">
    <strong>{{ translateRole(message.role) }}:</strong>
    <p [innerHTML]="parseMarkdown(message.content)"></p>
  </div>

  <div *ngIf="streamingMessage">
    <strong>Assistent:</strong>
    <p [innerHTML]="parseMarkdown(streamingMessage)"></p>
  </div>
</div>
<div mat-dialog-actions>
  <mat-form-field style="width: 100%;">
    <input matInput [(ngModel)]="userInput" (keyup.enter)="sendStreamingMessage()" placeholder="Type je bericht..." />
  </mat-form-field>
  <button mat-button (click)="onNoClick()">Sluiten</button>
</div>
